$(document).ready(function() {
	const button = $('.feedback__audio-icon');
	const allPlayers = $('.player');

	button.on('click', function() {
		let player = $(this).data('player');

		$('.player').trigger('stop');

		$(player).trigger('play');
		// if ($(player).paused === false) {
		// 	console.log('paused');
		// 	$(player).trigger('pause');
		// } else {
		// 	console.log('played');
		// 	$(player).trigger('play');
		// }
	});
});